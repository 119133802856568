import styled from '@emotion/styled'
import { useLocale } from '../../hooks/useLocale'
import { colors } from '../../styles/theme'

export const InputText = styled.input`
  border: 3px solid #b7d7ea;
  border-radius: 4px;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  transition: 0.3s;
  letter-spacing: 1px;
  color: ${colors.text.primary};
  font-weight: bold;
`

export const InputSelector = styled.select`
  border: 3px solid #b7d7ea;
  border-radius: 4px;
  background-color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 12px;
  transition: 0.3s;
  letter-spacing: 1px;
  color: ${colors.text.primary};
  font-weight: bold;
`

export const OptionsPlaytime = () => {
  const { t } = useLocale()
  return (
    <>
      <option value="0">0{t.時}</option>
      <option value="1">1{t.時}</option>
      <option value="2">2{t.時}</option>
      <option value="3">3{t.時}</option>
      <option value="4">4{t.時}</option>
      <option value="5">5{t.時}</option>
      <option value="6">6{t.時}</option>
      <option value="7">7{t.時}</option>
      <option value="8">8{t.時}</option>
      <option value="9">9{t.時}</option>
      <option value="10">10{t.時}</option>
      <option value="11">11{t.時}</option>
      <option value="12">12{t.時}</option>
      <option value="13">13{t.時}</option>
      <option value="14">14{t.時}</option>
      <option value="15">15{t.時}</option>
      <option value="16">16{t.時}</option>
      <option value="17">17{t.時}</option>
      <option value="18">18{t.時}</option>
      <option value="19">19{t.時}</option>
      <option value="20">20{t.時}</option>
      <option value="21">21{t.時}</option>
      <option value="22">22{t.時}</option>
      <option value="23">23{t.時}</option>
      <option value="24">24{t.時}</option>
      <option value="25">25{t.時}</option>
      <option value="26">26{t.時}</option>
      <option value="27">27{t.時}</option>
      <option value="28">28{t.時}</option>
      <option value="29">29{t.時}</option>
      <option value="30">30{t.時}</option>
    </>
  )
}

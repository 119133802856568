import styled from '@emotion/styled'
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import { logClickEvent } from '../../../firebase/analytics'
import { useLocale } from '../../../hooks/useLocale'
import { colors } from '../../../styles/theme'
import { InputSelector } from '../../atoms/Inputs'
import { PinkButton } from '@/components/atoms/Button'

export const Footer: React.FC<{}> = () => {
  const { locale, t } = useLocale()
  const clickAppInstallButton = useCallback(() => {
    logClickEvent('FooterAppInstallClick')
  }, [])
  const router = useRouter()
  const onChangeLang = useCallback((selectedLang: string) => {
    switch (selectedLang) {
      case 'ja':
        router.push(router.asPath, router.asPath, {
          locale: 'ja',
          scroll: true,
          shallow: true,
        })
        break
      case 'en':
        router.replace(router.asPath, router.asPath, {
          locale: 'en',
          scroll: true,
          shallow: true,
        })
        break
      default:
        router.replace(router.asPath, router.asPath, {
          locale: 'ja',
          scroll: true,
          shallow: true,
        })
        break
    }
  }, [])

  return (
    <Container>
      <FeatureBannerContainer>
        <FeatureBanner>
          <Link href={`/me/profiles/create`}>
            <BannerImage
              width={360}
              quality={30}
              height={126}
              alt={`自己紹介カード`}
              unoptimized={true}
              src={
                locale === 'ja'
                  ? '/static/images/banners/img_home_banner.png'
                  : '/static/images/banners/img_home_banner_en.jpg'
              }
            />
          </Link>
        </FeatureBanner>
        <FeatureBanner>
          <Link href={`https://twitter.com/gamee_games`} target={'_blank'}>
            <BannerImage
              width={360}
              height={126}
              quality={30}
              alt={`gamee's twitter`}
              unoptimized={true}
              src={
                locale === 'ja'
                  ? '/static/images/banners/img_twitter_gamee.png'
                  : '/static/images/banners/img_twitter_gamee_en.png'
              }
            />
          </Link>
        </FeatureBanner>
        <FeatureBanner>
          <Link href={`https://discord.gg/gamee-games`} target={'_blank'}>
            <BannerImage
              width={360}
              height={126}
              quality={30}
              alt={`gamee's discord`}
              unoptimized={true}
              src={
                locale === 'ja'
                  ? '/static/images/banners/img_gamee_discord.png'
                  : '/static/images/banners/img_gamee_discord_en.png'
              }
            />
          </Link>
        </FeatureBanner>
      </FeatureBannerContainer>
      <Divider />
      <AppInstallBanner onClick={clickAppInstallButton}>
        <ConversionText>
          \ 固定フレンドを探している人は
          <br /> Gameeのアプリ版をチェック🥳 /
        </ConversionText>
        <Link
          href={'https://gameegames.page.link/AdQw'}
          style={{
            textDecoration: 'none',
          }}
        >
          <Image
            src={'/static/images/img_app_install.jpg'}
            alt={'ゲーム友達募集アプリGameeのアプリ版をダウンロードしよう'}
            width={800}
            height={540}
            quality={30}
            unoptimized={true}
          />
          <DescriptionButtonText>
            \ 19~23時の時間がマッチしやすいよ！ /
          </DescriptionButtonText>
          <CreateBosyuButton onClick={clickAppInstallButton}>
            <AppInstallText>Gameeアプリからゲーム友達を探す</AppInstallText>
          </CreateBosyuButton>
        </Link>
      </AppInstallBanner>
      <Divider />
      <FormItemContainer>
        <LanguageContainer>Language</LanguageContainer>
        <InputSelector
          name="lang"
          value={locale}
          onChange={(e) => onChangeLang(e.target.value)}
        >
          <option value="ja">{'日本語'}</option>
          <option value="en">{'English'}</option>
        </InputSelector>
      </FormItemContainer>
      <FooterLink target="_blank" href="https://twitter.com/gamee_games">
        <Icon>
          <FontAwesomeIcon icon={faTwitterSquare} size="2x" color="#50abf1" />
        </Icon>
      </FooterLink>
      <Spacer />
      <HorizontalContainer>
        <FooterLink href="https://www.notion.so/f48ef70a6e9c4fd9a1dcf205084acfe1">
          {t.利用規約}
        </FooterLink>
        <FooterLink href="https://www.notion.so/4cae42f9c675490ca4b534c6c44aed9a">
          {t.プライバシーポリシー}
        </FooterLink>
        <FooterLink href="https://peing.net/ja/gamee_games">
          {t.ご要望はこちらから}
        </FooterLink>
        <FooterLink href="https://twitter.com/gamee_games">
          {t.公式Twitter}
        </FooterLink>
        <FooterLink href="https://twitter.com/messages/compose?recipient_id=1333975028073787395">
          {t.テンプレ提供はこちらから} <br />
          (Fortnite, DBD, AmongUs, VALORANT)
        </FooterLink>
      </HorizontalContainer>
      <VerticalContainer>
        <FooterLink href="/me/profiles/create/apex">
          {t.ApexLegends自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/apexMobile">
          {t.Apexモバイル自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/valorant">
          {t.VALORANT自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/fortnite">
          {t.FORTNITE自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/dbd">
          {t.DBD自己紹介カード}
        </FooterLink>
        <FooterLink href={'/me/profiles/create/amongus'}>
          {t.AmongUs自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/monsterhunterrise">
          {t.モンハンライズ自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/pokemonUnite">
          {t.ポケモンユナイト自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/fallguys">
          {t.フォールガイズ自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/splatoon3">
          {t.スプラトゥーン3自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/genshin">
          {t.原神自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/overwatch2">
          {t.Overwatch2自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/codMw2">
          {t.CoDMW2自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/gundamEvolution">
          {t.ガンエボ自己紹介カード}
        </FooterLink>
        <FooterLink href="/me/profiles/create/myHeroAcademiaUltraRumble">
          {t.ヒロアカUR自己紹介カード}
        </FooterLink>
      </VerticalContainer>
      <Copy>©︎2024 Gamee</Copy>
      <Divider />
      <ConversionText>
        \ アプリ版Gameeはサクサク動くし
        <br /> 通知も受け取れるよ🥳 /
      </ConversionText>
      <HorizontalContainer>
        <AppStoreDownloadButton>
          <Link href="https://gameegames.page.link/NEAo">
            <img
              height={41}
              width={115}
              src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83"
              alt="Download on the App Store"
            />
          </Link>
        </AppStoreDownloadButton>
        <GooglePlayStoreDownloadButton>
          <Link href="https://gameegames.page.link/NEAo">
            <Image
              src={'/static/images/img_google_play_badge.jpg'}
              alt="Download on the Google Play Store"
              height={41}
              width={125}
              unoptimized={true}
            />
          </Link>
        </GooglePlayStoreDownloadButton>
      </HorizontalContainer>
      <Spacer />
      <AppInstallBanner onClick={clickAppInstallButton}>
        <Link
          href={'https://gameegames.page.link/AdQw'}
          style={{
            textDecoration: 'none',
          }}
        >
          <Image
            src={'/static/images/img_profile_card_app_install_banner.png'}
            alt={'gamee app'}
            width={800}
            height={540}
            quality={30}
            unoptimized={true}
          />
          <DescriptionButtonText>
            \ 実力やプレイスタイルがぴったりなゲーム友達を見つけよう /
          </DescriptionButtonText>
          <CreateBosyuButton onClick={clickAppInstallButton}>
            <AppInstallText>Gameeアプリでゲーム友達を探す</AppInstallText>
          </CreateBosyuButton>
        </Link>
      </AppInstallBanner>
    </Container>
  )
}

const FooterLink = styled(Link)`
  color: #2cbbe9;
`

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
`

const FormItemContainer = styled.div`
  margin-bottom: 30px;
  margin-top: 40px;
`

const AppInstallBanner = styled.div`
  border-radius: 4px;
  overflow: hidden;
  max-width: 320px;
`

const LanguageContainer = styled.div`
  margin-bottom: 12px;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  color: ${colors.text.primary};
`

const HorizontalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`

const VerticalContainer = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`

const Icon = styled.div`
  width: 32px;
  height: 32px;
`

const Copy = styled.span`
  margin-top: 16px;
  font-size: 12px;
  color: ${colors.text.secondary};
`

const AppStoreDownloadButton = styled.div`
  border-radius: 13px;
  width: 115px;
  height: 41px;
`

const GooglePlayStoreDownloadButton = styled.div`
  border-radius: 13px;
  width: 125px;
  height: 41px;
`

const FeatureBannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const FeatureBanner = styled.div`
  width: 320px;
  margin: 0 16px 24px;
  margin-bottom: 24px;
  overflow: visible;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  line-height: 0;
`

const BannerImage = styled(Image)`
  border-radius: 10px;
`
const ConversionText = styled.h3`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.text.primary};
  text-align: center;
  margin-bottom: 16px;
  line-height: 1.8;
`

const DescriptionButtonText = styled.h3`
  margin-top: 20px;
  font-size: 11px;
  color: ${colors.text.secondary};
  text-align: center;
`

const CreateBosyuButton = styled(PinkButton)`
  margin: 8px auto;
  width: 320px;
  height: 40px;
  line-height: 42px;
  font-size: 14px;
`

const AppInstallText = styled.div`
  font-size: 15px;
  color: #fff;
`

const Divider = styled.div`
  width: 80%;
  margin: 32px auto;
  height: 1px;
  background-color: #dcdcdc;
`
const Spacer = styled.div`
  margin-top: 20px;
`
